import { useMemo } from 'react';

import {
  getToggleableMoneyCellConfig,
  getTrialMoneyCellConfig,
} from 'shared/components/ag-grid-cells/config';
import type {
  CondorColDef,
  CondorColGroupDef,
} from 'shared/components/ag-grid/types';

import type { ForecastedGridsResponse } from 'shared/lib/types';

import { forecastMonthColDefs } from './forecastMonthColDefs';

export default function useForecastedInvestigatorFeesColumnDefs(
  generatedForecast: ForecastedGridsResponse | undefined,
  showActuals: boolean,
): Array<CondorColDef | CondorColGroupDef> {
  return useMemo(() => {
    let forecastedMonths: string[] = [];
    if (generatedForecast) {
      forecastedMonths = Object.keys(generatedForecast.expenses[0]).filter(
        (key) => key.startsWith('forecasted_month_'),
      );
    }

    return [
      {
        headerName: '',
        children: [
          {
            field: 'costCategory',
            headerName: 'Category',
            pinned: 'left',
            rowGroup: true,
          },
          {
            field: 'region',
            headerName: 'Region',
            pinned: 'left',
          },
        ],
      },
      {
        headerName: 'Total LTD',
        children: [
          {
            headerName: 'LTD',
            ...getToggleableMoneyCellConfig('total_ltd_expense'),
            aggFunc: 'sum',
          },
          {
            headerName: 'Forecasted',
            field: 'forecasted_total',
            ...getTrialMoneyCellConfig(),
            aggFunc: 'sum',
          },
        ],
      },
      ...(showActuals
        ? [
            {
              headerName: 'Actual',
              children: [],
            },
          ]
        : []),
      {
        headerName: 'Forecasted',
        children: forecastMonthColDefs(forecastedMonths, true),
      },
    ];
  }, [generatedForecast, showActuals]);
}
