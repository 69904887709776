import { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import useForecast from '../hooks/useForecast';
import useForecastedCroExpenseGridOptions from '../hooks/useForecastedCroExpenseGridOptions';
import useForecastedInvestigatorFeesColumnDefs from '../hooks/useForecastedInvestigatorFeesColumnDefs';
import useForecastedInvestigatorFeesRows from '../hooks/useForecastedInvestigatorFeesRows';
import type {
  GenericGridProps,
  PeriodSpecificProps,
} from './ForecastedExpenseGridProps';

function OpenPeriodForecastedInvestigatorFeesExpenseGrid(
  props: PeriodSpecificProps,
) {
  const { sx, showActuals } = props;
  return (
    <GenericForecastedInvestigatorFeesExpenseGrid
      baseColDefs={undefined}
      processedExpenseData={[]}
      showActuals={showActuals}
      sx={sx}
    />
  );
}

function ClosedPeriodForecastedInvestigatorFeesExpenseGrid(
  props: PeriodSpecificProps,
) {
  // TODO: We don't have any data available for Closed Periods yet, so we're using open data
  return <OpenPeriodForecastedInvestigatorFeesExpenseGrid {...props} />;
}

function GenericForecastedInvestigatorFeesExpenseGrid(props: GenericGridProps) {
  const { sx, showActuals } = props;
  const { contractContainerSlug } = useParams<{
    contractContainerSlug?: string;
  }>();

  const { generatedForecast } = useForecast();

  const columnDefs = useForecastedInvestigatorFeesColumnDefs(
    generatedForecast,
    showActuals,
  );

  let forecastedMonths: string[] = [];
  if (generatedForecast) {
    forecastedMonths = Object.keys(generatedForecast.expenses[0]).filter(
      (key) => key.startsWith('forecasted_month_'),
    );
  }

  const gridOptions = useGridOptions(useForecastedCroExpenseGridOptions);

  const rowData = useForecastedInvestigatorFeesRows(
    generatedForecast,
    contractContainerSlug,
    forecastedMonths,
  );

  const userDisplayOptions = useMemo(
    () => ({ currencyViewMode: 'trial' as const }),
    [],
  );

  return (
    <CondorAgGrid
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      rowData={rowData}
      sx={sx}
      userDisplayOptions={userDisplayOptions}
    />
  );
}

export default function ForecastedInvestigatorFeesExpenseGrid(
  props: PeriodSpecificProps,
) {
  const { period } = useForecast();

  return period?.is_closed ? (
    <ClosedPeriodForecastedInvestigatorFeesExpenseGrid {...props} />
  ) : (
    <OpenPeriodForecastedInvestigatorFeesExpenseGrid {...props} />
  );
}
